import React from "react"
import Img from "gatsby-image"
import image from "../../../static/images/featured.png"
import { Link } from "gatsby"
import urlReplace from "../../utils/urlReplace"

function Card(props) {
  let excerpt
  if (props.excerpt) {
    excerpt = props.excerpt
  } else {
    excerpt = props.body
  }
  excerpt = excerpt.substring(0, 120)
  excerpt += "..."
  let link

  typeof props.url === "undefined"
    ? (link = (
        <>
          <Link to={`/articles/${urlReplace(props.text)}`}>Read more</Link>
        </>
      ))
    : (link = (
        <a href={props.url} target="_blank" rel="noreferrer">
          Read more
        </a>
      ))

  return (
    <div className="logoCard relative flex flex-col p-4 mb-4 text-center md:w-0-1/3">
      {props.featured ? (
        <img
          className="absolute top-0 left-0 z-10"
          src={image}
          alt="Featured"
        />
      ) : null}

      <div className="fix-for-card-images">
        <Img style={{ height: "175px" }} fluid={props.image} />
      </div>
      <h3 className="flex-1 text-2xl text-coupa-blue-dark sm:text-xl md:text-sm lg:text-xl pt-4">
        {props.text}
      </h3>
      <p
        className={`text-small flex-1 text-coupa-grey py-2 bg-none ${
          props.text.length > 50 ? "my-2" : "mt-2 mb-2"
        }`}
      >
        {excerpt}
      </p>
      <span
        className="text-base font-bold underline capitalize bg-none mt-2 mb-3 text-coupa-blue hover:text-coupa-blue-light"
        style={{ bottom: "10px" }}
      >
        {link}
      </span>
    </div>
  )
}

export default Card
