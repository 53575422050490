import React, { useEffect } from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import ArticleCardContainer from "../../components/ArticleCards/CardContainer"

function ArticlePage({ location, data }) {
  useEffect(() => {
    if (typeof window.twttr !== "undefined") {
      window.twttr.widgets.load()
    }
  })
  return (
    <Layout location={location} homeLinks>
      <SEO
        description="CEO of Coupa, Author, and Business advisor published Smarter Together: How communities are shaping the next revolution in business. Buy Today"
        keywords="Rob Bernshteyn, Smarter Together Book, Smarter Together: How Communities are Shaping the next Revolution in Business"
        url="https://robideas.com/"
        image="https://www.coupa.com/v2/assets/img/Rob_top_mobile.png"
      />
      <a href="./" className="anchor" id="aboutRob">
        {null}
      </a>
      <div className="articles-page">
        <ArticleCardContainer
          moreCardData={data.articleCards.frontmatter.cards}
          cardData={data.allMarkdownRemark.edges}
        />
      </div>
    </Layout>
  )
}

export default ArticlePage

export const pageQuery = graphql`
  query ArticlePageQuery {
    articleCards: markdownRemark(
      frontmatter: { markdownFile: { eq: "article-cards" } }
    ) {
      frontmatter {
        cards {
          node {
            frontmatter {
              date(formatString: "MMMM DD, YYYY")
              rawDate: date
              featuredimage {
                childImageSharp {
                  fluid(maxWidth: 400) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              featured
              url
              title
              excerpt
            }
          }
        }
      }
    }
    allMarkdownRemark(
      sort: { fields: frontmatter___date, order: DESC }
      filter: { frontmatter: { markdownFile: { ne: "article-cards" } } }
    ) {
      edges {
        node {
          id
          excerpt
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            rawDate: date
            title
            featured
            featuredimage {
              childImageSharp {
                fluid(maxWidth: 400) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
    heroBG: file(relativePath: { eq: "hero-bg.png" }) {
      childImageSharp {
        fluid(maxWidth: 1800) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    robBG: file(relativePath: { eq: "Rob_top_crop.png" }) {
      childImageSharp {
        fluid(maxWidth: 1800) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    robMobile: file(relativePath: { eq: "Rob_top_mobile.png" }) {
      childImageSharp {
        fluid(maxWidth: 1800) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
