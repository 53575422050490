const urlReplace = str => {
  str = str
    .replace(/:|\.| |_/g, "-")
    .replace(/\(|\)|\{|\}|\[|\]/g, "")
    .replace(/-+/g, "-")
    .toLowerCase()
  return str
}

export default urlReplace
