import React from "react"
import { nanoid } from "nanoid"
import { Link } from "gatsby"
import Card from "./Card"

function CardContainer(props) {
  let alCards = []

  if (props.moreCardData) {
    alCards = [...props.cardData, ...props.moreCardData]
  } else {
    alCards = [...props.cardData]
  }

  const featuredCards = alCards.filter(({ node }) => {
    if (node.frontmatter.featured) {
      return node
    }
    return null
  })

  const notFeaturedCards = alCards.filter(({ node }) => {
    if (!node.frontmatter.featured) {
      return node
    }
    return null
  })

  notFeaturedCards.sort((a, b) => {
    const d = a.node.frontmatter.rawDate.replace(/-|:|\.|\D/g, "")
    const e = b.node.frontmatter.rawDate.replace(/-|:|\.|\D/g, "")
    return e - d
  })

  featuredCards.sort((a, b) => {
    const d = a.node.frontmatter.rawDate.replace(/-|:|\.|\D/g, "")
    const e = b.node.frontmatter.rawDate.replace(/-|:|\.|\D/g, "")
    return e - d
  })

  let allCards = [...featuredCards, ...notFeaturedCards]

  const cards = allCards.map(({ node }) => {
    const id = nanoid()
    return (
      <Card
        text={node.frontmatter.title}
        date={node.frontmatter.date}
        featured={node.frontmatter.featured}
        url={node.frontmatter.url}
        image={node.frontmatter.featuredimage.childImageSharp.fluid}
        excerpt={node.frontmatter.excerpt}
        body={node.excerpt}
        key={id}
      />
    )
  })

  return (
    <>
      <div className="coupa-section bottom-border">
        <a className="anchor" id="news" href="/">
          {null}
        </a>
        <div className="container px-2 mx-auto logo-grid md:px-0">
          <h2 className="text-center">Recent Perspectives from Rob</h2>
          <h6 className="text-center mb-4 pb-4 font-normal breadcrumbs articles-crumbs">
            <Link
              className="font-medium text-coupa-blue bg-none hover:text-coupa-blue-light focus:text-coupa-blue-light"
              to="/"
            >
              Home
            </Link>
            &nbsp;/&nbsp;Articles
          </h6>

          <div className="flex flex-col md:flex-row flex-wrap justify-between">
            {cards}
          </div>
          <p className="text-center mb-0 mt-4">
            <Link
              to="/articles"
              id="view-all"
              className="mx-auto bg-coupa-blue coupa-button hover:bg-coupa-blue-light"
            >
              View All Articles
            </Link>
          </p>
        </div>
      </div>
    </>
  )
}

export default CardContainer
